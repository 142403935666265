




































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { OrdemDestinacaoService, ReceptorService, TransportadoraService } from '@/core/services/residuo';
import { OrdemDestinacao, Receptor} from '@/core/models/residuo';
import { PageBase } from '@/core/models/shared';
import { OrdemDestinacaoArquivo } from '@/core/models/residuo/OrdemDestinacaoArquivo';
import { ArquivoService } from '@/core/services/shared';

@Component
export default class ListaOrdemDestinacao extends PageBase {

  search: any = '';
  loading: boolean = false;
  dialogCadastro: boolean = false;
  totalLista: number = 0;
  lista: any[] = [];
  options: any = {
    itemsPerPage: 15
  };
  headers: any[] = [
    { text: '',         value:"actions" ,sortable: false },
    { text: 'Numero',   value: 'numero', type:'number' },
    { text: 'Data',     value: 'data' },
    { text: 'Receptor', value: 'receptor.nome' },
    { text: 'Detalhes', value: 'detalhes' },
    { text: 'Quantidade Total', value: 'quantidadeTotal' },
    { text: 'Transportadora',   value: 'transportadora.nome'},
    { text: 'MR02', value: 'arquivo.mr02' },
    { text: 'CDF02', value: 'arquivo.cdf02' },
    { text: 'Rel.Rec.2', value: 'arquivo.relatorioRecebimento2' },
    { text: 'MR03', value: 'arquivo.mr03' },
    { text: 'CDF03', value: 'arquivo.cdf03' },
    { text: 'Rel.Rec.3', value: 'arquivo.relatorioRecebimento3' },
    { text: 'MR04', value: 'arquivo.mr04' },
    { text: 'CDF04', value: 'arquivo.cdf04' },
    { text: 'Rel.Rec.4', value: 'arquivo.relatorioRecebimento4' },
  ];
  item = new OrdemDestinacao();
  service = new OrdemDestinacaoService();
  abrirItem: boolean = false;

  receptorService = new ReceptorService();
  receptores: Receptor[] =[];
  isReceptorLoading: boolean = false;
  onSearchReceptor: any = null;

  transportadoraService = new TransportadoraService();
  transportadoras: any[] = [];
  isTransportadoraLoading: boolean = false;
  onSearchTransportadora: any = null;
  arquivoService = new ArquivoService();

  sheet: boolean = false;
  filtro: any = {
    numero: null,
    receptorId: null,
    transportadoraId: null,
    dataInicial: '',
    dataFinal: '',
    mr02: '',
    mr03: '',
    mr04: ''
  }

  @Watch('onSearchReceptor')
  SearchCliente (val: string) {

    if (this.filtro.receptorId) return;
    if (this.isReceptorLoading) return;
    if (!val) return;

    this.isReceptorLoading = true
    this.receptorService.AutoComplete(val)
      .then(
          res => {
            this.receptores = res.data;
          },
          err => this.$swal('Aviso', err.response.data, 'error')
      )
      .finally(() => (this.isReceptorLoading = false));
  }

  @Watch('onSearchTransportadora')
  SearchTransportadora (val: string) {

    if (this.filtro.transportadoraId) return;
    if (this.isTransportadoraLoading) return;
    if (!val) return;

    this.isTransportadoraLoading = true
    this.transportadoraService.AutoComplete(val)
      .then(
        res => {
          this.transportadoras = res.data;
        },
        err => this.$swal('Aviso', err.response.data, 'error')
      )
      .finally(() => (this.isTransportadoraLoading = false));
  } 

  @Watch('options', { deep: true })
  Atualizar(isFiltro: boolean = false){

    if (isFiltro == true) {
      this.options.page = 1;
    }

    const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.options;
    this.loading = true;
    this.sheet = false;

    this.service.Listar(page, itemsPerPage, sortBy, sortDesc, this.search, this.headers, this.service.MontaFiltro(this.filtro), 'Receptor, Transportadora, Residuos, Arquivo').then(
      res => {
        this.lista = res.data.items;
        this.totalLista = res.data.count;
      },
      err => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else {
          this.$swal("Aviso", err.response.data, "error");
        }
      }
    )
    .finally(() => (this.loading = false));
  }
  
  Excluir(item: OrdemDestinacao){
    this.$swal({
      title: 'Atenção!',
      text: 'Tem certeza que deseja excluir o registro atual?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
          return this.service.Excluir(item.id)
                  .then(res => {
                      if (res.status == 200){
                          return res.data;
                      }
                  },
                  err => {
                    if (err.response.status == 403){
                      this.$swal("Aviso", "Você não tem permissão para essa funcionalidade!", "warning");                
                    }
                    else{
                      this.$swal('Aviso', err.response.data, 'error')
                    }
                  }
              )
          },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading()
      })
      .then((result) => {
          if(result.value) {
              this.$swal("Aviso", result.value, "success");
              this.Atualizar();
          }
      })
  }

  AbrirDialogCadastro(item?: OrdemDestinacao){
    
    if(item && !this.abrirItem){  
      this.abrirItem = true;    
      this.service.ObterPorId(item.id, 'Residuos.Unidade, Residuos.Residuo, Residuos.OrdemServico.Contrato.Cliente, Transportadora, Receptor, ContratoReceptor.Receptor, Arquivo, Motorista, Veiculo, ServicosTransportadora.Servico, ServicosTransportadora.UnidadeMedida').then(
        res => {
          this.item = new OrdemDestinacao(res.data);
          // Processo 2
          this.item.processo2.dataMr02 = item.processo2.dataMr02 ? item.processo2.dataMr02.toDateYYYYMMDD(): '';
          this.item.processo2.dataCdf02 = item.processo2.dataCdf02 ? item.processo2.dataCdf02.toDateYYYYMMDD(): '';
          this.item.processo2.dataRelatorioRecebimento = item.processo2.dataRelatorioRecebimento ? item.processo2.dataRelatorioRecebimento.toDateYYYYMMDD():'';
          this.item.processo2.dataTecnologia = this.item.processo2.dataTecnologia ? this.item.processo2.dataTecnologia.toDateYYYYMMDD() : '';
          // Processo 3
          this.item.processo3.dataMr03 = item.processo3.dataMr03 ? item.processo3.dataMr03.toDateYYYYMMDD() : '';
          this.item.processo3.dataCdf03 = item.processo3.dataCdf03 ? item.processo3.dataCdf03.toDateYYYYMMDD() : '';
          this.item.processo3.dataRelatorioRecebimento = item.processo3.dataRelatorioRecebimento ? item.processo3.dataRelatorioRecebimento.toDateYYYYMMDD() : '';
          this.item.processo3.dataTecnologia = this.item.processo3.dataTecnologia ? this.item.processo3.dataTecnologia.toDateYYYYMMDD() : '';
          // Processo 4
          this.item.processo4.dataMr04 = item.processo4.dataMr04 ? item.processo4.dataMr04.toDateYYYYMMDD() :'';
          this.item.processo4.dataCdf04 = item.processo4.dataCdf04 ? item.processo4.cdf04.toDateYYYYMMDD() : '';
          this.item.processo4.dataRelatorioRecebimento = item.processo4.dataRelatorioRecebimento ? item.processo4.dataRelatorioRecebimento.toDateYYYYMMDD() : '';
          this.item.processo4.dataTecnologia = this.item.processo4.dataTecnologia ? this.item.processo4.dataTecnologia.toDateYYYYMMDD() : '';
          
          this.dialogCadastro = true;
        }
      ).finally(()=>{
        this.abrirItem = false;
      })
    }
    else{
      this.item = new OrdemDestinacao();
      this.item.empresaId = this.app.empresaId;
      this.dialogCadastro = true;
    }
  }

  AbrirJanela(id: number, rota: string) {
    let routeLink = this.$router.resolve({ name: rota, params: { id: id.toString() } });
    window.open(routeLink.href, '_blank');
  }

  Imprimir(){
    let routeLink = this.$router.resolve({name: "relatorioOrdemDestinacao",
      query:{
        numero: this.filtro.numero,
        receptorId: this.filtro.receptorId,
        dataInicial: this.filtro.dataInicial,
        dataFinal: this.filtro.dataFinal
      }
    });

    window.open(routeLink.href, '_blank');
  }
  Download(item:OrdemDestinacao, tipo:string){
    this.service.Download(item.id, 0, tipo).then(
      async(res) =>{
        
        if(res.status == 204){
          return;
        }
        switch(tipo){
          case 'MR02':
            await this.arquivoService.Download(res.data, res.headers['content-type'], item.arquivo.mr02!.nome);
            break;
          case 'CDF02':
            await this.arquivoService.Download(res.data, res.headers['content-type'], item.arquivo.cdf02!.nome);
            break;
          case 'RelatorioRecebimento2':
            await this.arquivoService.Download(res.data, res.headers['content-type'], item.arquivo.relatorioRecebimento2!.nome);
            break;
          case 'MR03':
            await this.arquivoService.Download(res.data, res.headers['content-type'], item.arquivo.mr03!.nome);
            break;
          case 'CDF03':
            await this.arquivoService.Download(res.data, res.headers['content-type'], item.arquivo.cdf03!.nome);
            break;
          case 'RelatorioRecebimento3':
            await this.arquivoService.Download(res.data, res.headers['content-type'], item.arquivo.relatorioRecebimento3!.nome);
            break;
          case 'MR04':
            await this.arquivoService.Download(res.data, res.headers['content-type'], item.arquivo.mr04!.nome);
            break;
          case 'CDF04':
            await this.arquivoService.Download(res.data, res.headers['content-type'], item.arquivo.cdf04!.nome);
            break;
          case 'RelatorioRecebimento4':
            await this.arquivoService.Download(res.data, res.headers['content-type'], item.arquivo.relatorioRecebimento4!.nome);
            break;
          default:
            console.warn(`Não existe esse tipo: ${tipo}.`);
        }
      }
    );
    (err) => this.$swal("Aviso",err.message,"error")
  }
}
